import { envConfig } from "./env";

export const SPWN_URL = "https://spwn.jp";
export const SPWN_ACCOUNT_EDIT_URL = "https://spwn.jp/account/edit";
export const SPWN_ACCOUNT_TICKET_URL = "https://spwn.jp/account/ticket";
export const SPWN_ACCOUNT_SETTLEMENT_HISTORY_URL =
  "https://spwn.jp/account/settlement/history";
export const SPWN_API_URL = envConfig.NEXT_PUBLIC_USE_EMULATOR
  ? "http://127.0.0.1:5001/bls-boost/us-central1"
  : envConfig.NEXT_PUBLIC_SPWN_API_URL;
export const SPWN_SUPPORT_URL = "https://spwn.zendesk.com/hc";
